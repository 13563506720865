<template>
  <el-dialog
    title="自定义商品组"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-container>
        <el-aside class="Aside" width="300px">
          <el-row
            type="flex"
            class="row-bg"
            justify="end"
            style="padding: 0px 0px 10px 0px"
          >
            <el-button type="primary" icon="el-icon-edit" @click="handleAdd">
              添加分组
            </el-button>
            <!-- <el-button type="danger" icon="el-icon-delete" @click="dler">
              删除
            </el-button> -->
          </el-row>
          <el-col>
            <u-table
              v-loading="l_loading"
              height="510"
              :data="list"
              border
              style="width: 100%"
              :row-class-name="tableRowClassName"
              @row-click="routerClass"
            >
              <u-table-column
                v-for="(tables, index) in tableList"
                :key="index"
                :prop="tables.prop"
                :label="tables.label"
                :width="tables.width"
                :align="tables.align"
              ></u-table-column>
              <u-table-column prop="" align="center" label="操作" width="90px">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click.native.prevent.stop="
                      handleDeleteGroup(scope.$index, scope.row)
                    "
                  >
                    删除
                  </el-button>
                </template>
              </u-table-column>
            </u-table>
          </el-col>
        </el-aside>
        <el-main class="Main">
          <div
            v-if="currentGoodsGroup"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
            "
          >
            <div style="display: flex; align-items: center">
              <div style="width: 110px; margin-right: 10px; text-align: right">
                商品组名称:
              </div>
              <el-input
                v-model="currentGoodsGroup.name"
                style="width: 260px"
              ></el-input>
            </div>
            <div>
              <el-button type="primary" @click="addGoods">添加商品</el-button>
            </div>
          </div>
          <u-table
            v-loading="r_loading"
            :data="rlist"
            use-virtual
            height="510"
            border
            :data-changes-scroll-top="false"
            style="width: 100%"
          >
            <!-- 排序 -->
            <u-table-column width="80" label="排序" align="center" prop="sort">
              <template #default="{ row }">
                <el-input
                  v-model.trim.number="row.sort_order"
                  type="number"
                  @change="blur_"
                ></el-input>
              </template>
            </u-table-column>

            <u-table-column
              prop="name"
              label="商品名称"
              width="230"
              align="center"
            >
              <template #default="{ row }">
                <goods-search
                  :f-key="row.goods_name"
                  @select-goods-all="selectGoods($event, row)"
                  @add-rows="addRows"
                ></goods-search>
              </template>
            </u-table-column>
            <u-table-column prop="name" label="规格" width="140" align="center">
              <template #default="{ row }">
                {{ row.goods_specs }}
              </template>
            </u-table-column>
            <!-- <u-table-column
              v-for="(routers, index) in columns"
              :key="index"
              :prop="routers.prop"
              :label="routers.label"
              :width="routers.width"
              :align="routers.align"
            ></u-table-column> -->
            <u-table-column
              prop=""
              align="center"
              label="操作"
              fixed="right"
              min-width="140px"
            >
              <template slot-scope="scope">
                <!-- <el-button
                  type="text"
                  @click.native.prevent="addGoods(scope.$index, scope.row)"
                >
                  添加
                </el-button> -->

                <el-button
                  type="text"
                  @click.native.prevent="deleteGoods(scope.$index, scope.row)"
                >
                  删除
                </el-button>
              </template>
            </u-table-column>
          </u-table>
          <el-button
            v-if="currentGoodsGroup"
            style="float: right; margin-top: 10px"
            type="primary"
            @click="hanldeSave"
          >
            保存
          </el-button>
        </el-main>
      </el-container>
    </div>
    <add ref="add" @refresh="getGroup"></add>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { postAction } from '@/api/Employee'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import Add from './add'
  export default {
    name: 'Group',
    components: { GoodsSearch, Add },
    data() {
      return {
        showDialog: false,
        l_loading: false,
        r_loading: false,
        list: [],
        rlist: [],
        currentGoodsGroup: null,
        tableClass: 0,
        tableList: [
          {
            prop: 'name',
            label: '已创建商品分组',
            width: '180',
            align: 'center',
          },
        ],
      }
    },
    computed: {
      sortRlist() {
        return _.sortBy(this.rlist, (item) => item.sort_order)
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.getGroup()
          // this.addRow()
        } else {
          this.currentGoodsGroup = null
          this.rlist = []
          this.list = []
          this.$emit('refresh')
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 获取已创建分组
      getGroup() {
        this.l_loading = true
        postAction('/baseAdmin/goods-group/index', {
          pageNo: 1,
          pageSize: -1,
        }).then((r) => {
          this.list = r.data
          this.l_loading = false
        })
      },
      // 获取分组详情
      getGroupDetail(row) {
        this.r_loading = true
        postAction('/baseAdmin/goods-group/detail', { id: row.id }).then(
          (r) => {
            this.r_loading = false
            this.rlist = r.data.goods_data
          }
        )
      },

      // 分组点击
      routerClass(row) {
        console.log('rr', row)
        this.currentGoodsGroup = JSON.parse(JSON.stringify(row))
        this.getGroupDetail(row)
      },
      addRow() {
        this.rlist.push({
          goods_id: '', // '商品id',
          goods_name: '', // '商品名称',
          goods_specs: '', // '规格',
          sort_order: 0, // '排序',
        })
      },
      // 排序输入框失去焦点 排序
      blur_() {
        console.log('123')
        this.sort_()
      },
      // 排序
      sort_() {
        this.rlist = _.sortBy(this.rlist, (item) => item.sort_order)
      },

      tableRowClassName({ row, rowIndex }) {
        if (rowIndex === this.tableClass) {
          return 'warning-row'
        } else {
          return 'success-row'
        }
      },
      // 添加商品
      addGoods(index, row) {
        if (this.currentGoodsGroup) {
          this.addRow()
        }
      },
      handleAdd() {
        this.$refs.add.showDialog = true
      },

      // 删除分组
      handleDeleteGroup(index, row) {
        console.log('删除分组', row)
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            postAction('/baseAdmin/goods-group/delete', { id: row.id }).then(
              (r) => {
                this.$message.success('删除成功')
                this.$emit('refresh')
                if (
                  this.currentGoodsGroup &&
                  row.id == this.currentGoodsGroup.id
                ) {
                  this.currentGoodsGroup = null
                  this.rlist = []
                }
                this.getGroup()
              }
            )
          })
          .catch(() => {
            console.log('no')
          })
      },
      hanldeSave() {
        if (this.currentGoodsGroup) {
          let gd = JSON.parse(JSON.stringify(this.rlist))
          let goods_data = []
          gd.forEach((item) => {
            if (item.goods_id) {
              goods_data.push(item)
            }
          })
          goods_data =
            goods_data.length == 0 ? '[]' : JSON.stringify(goods_data)
          postAction('/baseAdmin/goods-group/update', {
            id: this.currentGoodsGroup.id,
            name: this.currentGoodsGroup.name,
            goods_data,
          }).then((r) => {
            this.$message.success('修改成功')
            this.getGroup()
            // this.routerClass(currentGoodsGroup)
          })
        }
      },
      selectGoods(val, row) {
        row.goods_id = val.goods_id
        row.goods_specs = val.specs
        row.goods_name = val.goods_name
        row.sort_order = 0
      },
      addRows(val) {
        val.forEach((item) => {
          this.rlist.push({
            goods_id: item.goods_id,
            goods_specs: item.specs,
            goods_name: item.goods_name,
            sort_order: 0,
          })
        })
      },
      deleteGoods(index, row) {
        if (this.rlist.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.rlist.splice(index, 1)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .Aside {
    align-items: center;

    padding: 20px;
    text-align: center;
  }
  .text {
    padding: 10px 20px;
    background-color: #ffffff;
    border: #000000 1px solid;
  }
  .texts {
    padding: 10px 20px;
    color: #ffffff;
    background-color: #0aaafa;
    border: #000000 1px solid;
  }
  /* .warning-row {
    color: #ffffff;
    background: #328ffe;
  } */
  .u-table .warning-row {
    color: #ffffff;
    background: #1890ff;
  }

  .u-table .success-row {
    background: #ffffff;
  }
  .num {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px 0px 20px;
  }
</style>
