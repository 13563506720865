<template>
  <div class="orderTest-container">
    <!-- NAME[epic=基础] 商品资料 -->
    <el-form :model="form" label-width="80px" :inline="true">
      <el-form-item prop="class">
        <class-select
          ref="classSelect"
          :popper-class="'select-idx'"
          @class-select-change="chooseClass"
        ></class-select>

        <!-- <el-select
          v-model="form.class"
          filterable
          placeholder="品类"
          style="width: 130px"
        >
          <el-option
            v-for="item in classList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item prop="brand">
        <brand-select
          ref="brandSelect"
          style="width: 160px"
          :popper-class="'select-idx'"
          @brand-select-change="chooseBrand"
        ></brand-select>

        <!-- <el-select
          v-model="form.brand"
          filterable
          placeholder="品牌"
          style="width: 130px"
        >
          <el-option
            v-for="item in brandList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item prop="sale_type">
        <el-select
          v-model="queryTableForm.sale_type"
          placeholder="通路"
          style="width: 140px"
          popper-class="select-idx"
          clearable
        >
          <el-option
            v-for="item in stateList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="is_img">
        <el-select
          v-model="queryTableForm.is_img"
          placeholder="图片"
          :popper-class="'select-idx'"
          style="width: 140px"
          clearable
        >
          <el-option
            v-for="item in picList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="goods_group">
        <el-select
          v-model="queryTableForm.goods_group"
          :popper-class="'select-idx'"
          placeholder="商品分组"
          style="width: 130px"
          clearable
        >
          <el-option
            v-for="item in groupList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="searchVal">
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          :popper-class="'select-idx selectDC'"
          :goods-list-obj="{ is_set: 1 }"
          @input-goods="setkeyword"
          @select-search-goods="setkeyword"
        ></goods-search>

        <!-- <el-autocomplete
          v-model="form.searchVal"
          popper-class="selectDC"
          placeholder="输入名称、简拼"
          style="width: 150px"
          @select="tableSearch"
        >
          <i
            slot="suffix"
            class="el-icon-search el-input__icon"
            @click="searchIconClick()"
          ></i>
          <template slot-scope="{ item }">
            <div class="wrap" style="display: flex">
              <div class="name" style="margin-right: 10px">
                id:{{ item.id }}
              </div>
              <span class="addr">商品名称:{{ item.value }}</span>
            </div>
          </template>
        </el-autocomplete> -->
      </el-form-item>
      <el-form-item prop="checked">
        <el-checkbox
          v-model="queryTableForm.is_close"
          :true-label="1"
          :false-label="0"
        >
          已封存
        </el-checkbox>
      </el-form-item>
      <el-form-item prop="checked">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item prop="checked">
        <el-button @click="handleImport">导入</el-button>
      </el-form-item>
      <el-form-item prop="checked">
        <el-button @click="handleImportMore">导入多口味</el-button>
      </el-form-item>
      <el-form-item prop="checked">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
      <el-form-item prop="checked">
        <el-button @click="handleExportMore">导出多口味</el-button>
      </el-form-item>
      <el-form-item prop="checked">
        <el-button @click="handleExportList">按列表导出</el-button>
      </el-form-item>
      <el-form-item prop="checked">
        <el-button type="primary" @click="addGoods">添加商品</el-button>
      </el-form-item>
      <el-form-item prop="checked">
        <el-button type="primary" @click="handleGroup">自定义商品组</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <div class="tableWrap">
      <!-- <div class="setFilter">
        <el-popover popper-class="custom-table-checkbox" trigger="hover">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="index"
              :label="item.label"
            ></el-checkbox>
          </el-checkbox-group>
          <el-button slot="reference">
            <vab-remix-icon icon="settings-line" />
          </el-button>
        </el-popover>
      </div> -->
      <el-table
        ref="tableSort"
        v-loading="tableLoading"
        stripe
        :data="list"
        row-key="id"
        @selection-change="handleTableChange"
      >
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
          :reserve-selection="true"
        ></el-table-column>
        <!-- 序号 -->
        <!-- <el-table-column prop="" align="center" label="序号" min-width="50px">
        <template #default="{ $index }">
          <span>{{ $index + 1 }}</span>
        </template>
      </el-table-column> -->

        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          :prop="item.prop"
          :align="item.align"
          :label="item.label"
          width=""
        >
          <!-- 排序 -->
          <template v-if="item.label === '排序'" #default="{ row }">
            <el-input
              v-model.trim="row.sort"
              @change="blur_(row, row.sort)"
            ></el-input>
            <!-- {{ row.sort }} -->
          </template>
          <template v-else-if="item.label == '可改价'" #default="{ row }">
            <el-tooltip
              :content="
                row.sell_price_change == false ? '点击开启' : '点击关闭'
              "
              :enterable="false"
              placement="top"
            >
              <el-switch
                v-model="row.sell_price_change"
                @change="handlerSwitch(row)"
              ></el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- <el-table-column
        prop="goods_name"
        align="center"
        label="商品名称"
        min-width="130px"
      ></el-table-column>
      <el-table-column
        prop="class"
        align="center"
        label="类别"
        min-width="80px"
      ></el-table-column>
      <el-table-column
        prop="brand"
        align="center"
        label="品牌"
        min-width="120px"
      ></el-table-column>
      <el-table-column
        prop="specifications"
        align="center"
        label="规格"
        min-width="90px"
      ></el-table-column>
      <el-table-column
        prop="state"
        align="center"
        label="通路"
        min-width="80px"
      ></el-table-column>
      <el-table-column
        prop="pic"
        align="center"
        label="图片"
        min-width="60px"
      ></el-table-column>
      <el-table-column
        prop="sell_price_change"
        align="center"
        label="可改价"
        min-width="80px"
      >
        <template #default="{ row }">
          <el-tooltip
            :content="row.sell_price_change === false ? '点击开启' : '点击关闭'"
            :enterable="false"
            placement="top"
          >
            <el-switch v-model="row.sell_price_change"></el-switch>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="units"
        align="center"
        label="单位换算"
        min-width="140px"
      ></el-table-column> -->
        <el-table-column
          prop=""
          align="center"
          label="操作"
          fixed="right"
          min-width="160px"
        >
          <template #default="{ $index, row }">
            <el-button
              type="text"
              @click.native.prevent="handleCheck($index, row)"
            >
              查看
            </el-button>
            <el-button
              v-if="row.is_close != 1"
              type="text"
              @click.native.prevent="editRow($index, row)"
            >
              修改
            </el-button>
            <el-button
              v-if="row.is_close != 1"
              type="text"
              @click.native.prevent="copyRow($index, row)"
            >
              复制
            </el-button>
            <el-button
              v-if="row.is_close == 0"
              type="text"
              @click.native.prevent="saveRow($index, row)"
            >
              封存
            </el-button>
            <el-button
              v-if="row.is_close == 1"
              type="text"
              @click.native.prevent="qiyong($index, row)"
            >
              启用
            </el-button>
            <el-button
              v-if="row.is_close == 1"
              type="text"
              @click.native.prevent="deleteRow($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-row style="margin-top: 15px">
          <el-col :span="5">
            <el-button type="warning" @click="handleBatchEdit">
              批量修改
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-pagination
              :current-page="queryTableForm.pageNo"
              :layout="layout"
              :page-size="queryTableForm.pageSize"
              :total="total"
              background
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </el-col>
        </el-row>
      </div>
    </div>

    <table-edit ref="tableEdit" @refresh="fetchData"></table-edit>
    <batch-edit
      ref="batchEdit"
      @refresh="fetchData"
      @settabelist="settabelist"
    ></batch-edit>
    <import-tpl ref="importTpl"></import-tpl>
    <group
      ref="group"
      @refresh="
        () => {
          queryTableForm.goods_group = ''
          fetchData()
          getSelectList()
        }
      "
    ></group>
  </div>
</template>

<script>
  import _ from 'lodash'
  import TableEdit from './components/edit'
  import {
    getGoodsList,
    deleteGoods,
    someF,
    copyRow,
    saveGoods,
    exportGoods,
  } from '@/api/goodsDetail'
  import { goodsDetailList } from '@/api/selectList'
  import BrandSelect from '@/baseComponents/brandSelect'
  import ClassSelect from '@/baseComponents/classSelect'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { getSelect } from '@/api/chooseClientDialog'
  import BatchEdit from './components/batchEdit'
  import Group from './components/group'
  import ImportTpl from '@/views/index/components/importTpl.vue'
  import { downloadFile } from '@/api/Employee'

  export default {
    name: 'GoodsDetail',
    components: {
      TableEdit,
      BrandSelect,
      ClassSelect,
      GoodsSearch,
      BatchEdit,
      ImportTpl,
      Group,
    },
    data() {
      return {
        selectRow: [],
        tableLoading: true,
        form: {
          class: '',
          brand: '',
          state: '',
          pic: '',
          group: '',
          searchVal: '',
          checked: false,
        },
        // classList: [
        //   {
        //     value: '白酒',
        //     label: '白酒',
        //   },
        //   {
        //     value: '饮料',
        //     label: '饮料',
        //   },
        //   {
        //     value: '食品',
        //     label: '食品',
        //   },
        // ],
        // brandList: [
        //   {
        //     value: '白酒',
        //     label: '白酒',
        //   },
        //   {
        //     value: '饮料',
        //     label: '饮料',
        //   },
        //   {
        //     value: '食品',
        //     label: '食品',
        //   },
        // ],
        stateList: [],
        picList: [],
        groupList: [],
        selectRes: [{}],
        // 表格
        checkList: [
          '排序',
          '编码',
          '商品名称',
          '规格',
          '助记码',
          '条码',
          '类别',
          '品牌',
          '通路',
          '图片',
          '售价',
          '可改价',
          '单位换算',
        ],
        columns: [
          {
            order: 1,
            label: '排序',
            align: 'center',
            width: '75px',
            prop: 'sort',
            sortable: false,
          },
          {
            order: 2,
            prop: 'id',
            align: 'center',
            label: '编码',
            width: '140px',
          },
          {
            order: 3,
            prop: 'goods_name',
            align: 'center',
            label: '商品名称',
            width: '200px',
          },
          {
            order: 3.1,
            prop: 'specs',
            align: 'center',
            label: '规格',
            width: '120px',
          },
          {
            order: 3.2,
            prop: 'easy_code',
            align: 'center',
            label: '助记码',
            width: '130px',
          },
          {
            order: 3.3,
            prop: 'unit_code_sm',
            align: 'center',
            label: '条码',
            width: '130px',
          },
          {
            order: 4,
            prop: 'class_name',
            align: 'center',
            label: '类别',
            width: '120px',
          },
          {
            order: 5,
            prop: 'brand_name',
            align: 'center',
            label: '品牌',
            width: '130px',
          },

          {
            order: 7,
            prop: 'sale_type_text',
            align: 'center',
            label: '通路',
            width: '100px',
          },
          {
            order: 8,
            prop: 'is_img',
            align: 'center',
            label: '图片',
            width: '80px',
          },
          {
            order: 8.1,
            prop: 'price_str',
            align: 'center',
            label: '售价',
            width: '140px',
          },
          {
            order: 9,
            prop: 'sell_price_change',
            align: 'center',
            label: '可改价',
            width: '100px',
          },
          {
            order: 10,
            prop: 'unit_cv',
            align: 'center',
            label: '单位换算',
            width: '140px',
          },
        ],
        list: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryTableForm: {
          pageNo: 1,
          pageSize: 10,
          goods_group: '',
          sale_type: '', // 通路
          brand_id: '', //品牌
          goods_id: '', //请输入名称、简拼或助记码
          class_id: '', //品类
          is_close: '', //是否封存 0未封 1已封
          is_img: '', //是否有图 0无 1有 为空或不传不判断
        },
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    created() {
      // this.sort_()
      this.fetchData()
      // 获取下拉
      this.getSelectList()
    },
    methods: {
      settabelist() {
        console.log('清空这个选中')
        this.selectRow = []
        this.$refs.tableSort.clearSelection()
      },
      // 获取表格数据
      async fetchData() {
        let { data, totalCount } = await getGoodsList(this.queryTableForm)
        console.log(data)
        this.total = totalCount
        this.list = data
        this.list.forEach((item) => {
          item.sell_price_change = item.sell_price_change == 1 ? false : true
        })
        this.tableLoading = false
      },
      async getSelectList() {
        let { data, msg, code } = await goodsDetailList()
        if (code == 200) {
          console.log(data)
          this.groupList = data.goods_group
          this.picList = data.is_img
          this.stateList = data.sale_type
        }
      },
      handleCurrentChange(val) {
        console.log(val)
        this.queryTableForm.pageNo = val
        this.fetchData()
      },
      handleImport() {
        this.$refs.importTpl.data.type = 1
        this.$refs.importTpl.title = '批量导入商品'
        this.$refs.importTpl.showDialog = true
      },
      // 导出
      handleExport() {
        //         /baseAdmin/goods/export-goods
        // 后台商品导出
        // exportGoods(this.form).then((res) => {
        //   console.log(res)
        //   let a = document.createElement('a')
        //   let blob = new Blob([res], {
        //     type: 'application/vnd.ms-excel',
        //   })
        //   let objectUrl = window.URL.createObjectURL(blob)
        //   a.setAttribute('href', objectUrl)
        //   a.setAttribute('download', '结果.xlsx')
        //   console.log(a)
        //   a.click()
        // })
        downloadFile(
          '/baseAdmin/goods/export-goods',
          '商品资料.xlsx',
          this.queryTableForm
        )
      },
      handleExportList() {
        downloadFile(
          '/baseAdmin/goods/export-goods2',
          '商品资料(按列表).xlsx',
          this.queryTableForm
        )
      },
      handleImportMore() {
        this.$refs.importTpl.data.type = 13
        this.$refs.importTpl.title = '批量导入多口味商品'
        this.$refs.importTpl.showDialog = true
      },
      handleExportMore() {
        downloadFile(
          '/baseAdmin/goods/export-goods-sub',
          '多口味导出.xlsx',
          this.queryTableForm
        )
      },
      handleSizeChange(val) {
        console.log(val)
        this.queryTableForm.pageSize = val
        this.fetchData()
      },
      // 排序输入框失去焦点 排序
      blur_(row, val) {
        // this.sort_(row)
        console.log(row, val)
        someF({ id: row.id, field: 'sort', value: val }).then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.$message.success('排序成功')
            this.fetchData()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // // 排序
      // sort_(row) {
      //   this.list = _.sortBy(this.list, (item) => item.sort)
      //   console.log(row)
      // },
      // 表格内商品名称输入框搜索
      createStateFilter(queryString) {
        return (state) => {
          return (
            state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          )
        }
      },
      async querySearch(val, cb) {
        // let selectData = this.selectRes.data.client
        // // let clientRes = await request({
        // //   url: '/advance/clientSelect',
        // //   method: 'get',
        // // })
        // // this.selectRes = clientRes.data.client
        // console.log(selectData)
        // var results = val
        //   ? selectData.filter(this.createStateFilter(val))
        //   : selectData
        let results = [{}]
        // cb(results)
      },
      handleCheck(index, row) {
        this.$refs['tableEdit'].id = row.id
        this.$refs['tableEdit'].title = '查看商品'
        this.$refs['tableEdit'].isEdit = 0
        this.$refs['tableEdit'].showDialog = true
      },
      setkeyword(val) {
        this.queryTableForm.keyword = val
      },
      // 表格内编辑
      editRow(index, row) {
        this.$refs['tableEdit'].showDialog = true
        this.$refs['tableEdit'].id = row.id
        this.$refs['tableEdit'].title = '修改商品'
        this.$refs['tableEdit'].isEdit = 1
      },
      copyRow(index, row) {
        // let rowS = JSON.parse(JSON.stringify(row))
        // let rowa = Object.assign(rowS, { id: row.id++, edit: false })
        // this.list.splice(index, 0, rowa)
        // console.log(rowa)
        copyRow({ id: row.id }).then((res) => {
          if (res.code == 200) {
            this.$message.success('复制成功')
            this.fetchData()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      chooseClass(val) {
        this.queryTableForm.class_id = val
      },
      chooseBrand(val) {
        this.queryTableForm.brand_id = val
      },
      // chooseKeyword(val) {
      //   this.queryTableForm.keyword = val
      // },
      selectId(val) {
        console.log(val)
        this.queryTableForm.keyword = val.goods_name
      },
      saveRow(index, row) {
        console.log('封存')
        this.$confirm('确定封存此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            saveGoods({
              id: row.id,
            }).then((res) => {
              if (res.code == 200) {
                this.$message.success('封存成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      qiyong(index, row) {
        console.log('启用')
        this.$confirm('确定启用此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            saveGoods({
              id: row.id,
            }).then((res) => {
              if (res.code == 200) {
                this.$message.success('启用成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      deleteRow(index, row) {
        // if (this.queryTableForm.pageNo == 1 && this.list.length == 1) {
        //   // alert('不能删除最后一行')
        //   this.$message.error('不能删除最后一行')
        //   return
        // }
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteGoods({ id: row.id }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('删除成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleQuery() {
        this.queryTableForm.pageSize = 10
        this.queryTableForm.pageNo = 1
        this.fetchData(this.queryTableForm)
      },
      addGoods() {
        this.$refs['tableEdit'].id = 0
        this.$refs['tableEdit'].isEdit = 1
        this.$refs['tableEdit'].title = '添加商品'
        this.$refs['tableEdit'].showDialog = true
      },
      handlerSwitch(row) {
        let val = row.sell_price_change == false ? '1' : '2'

        someF({ id: row.id, field: 'sell_price_change', value: val }).then(
          (res) => {
            if (res.code == 200) {
              this.$message.success(res.msg)
            } else {
              this.$message.error(res.msg)
            }
          }
        )
      },
      handleTableChange(rows) {
        this.selectRow = rows
      },
      // 批量修改
      handleBatchEdit() {
        if (this.selectRow.length == 0) {
          this.$message.error('请勾选商品')
        } else {
          let ids = []
          this.selectRow.forEach((i) => {
            ids.push(i.id)
          })
          this.$refs.batchEdit.goods_ids = ids.join()
          this.$refs.batchEdit.showDialog = true
        }
      },
      handleGroup() {
        this.$refs.group.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
