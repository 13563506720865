<template>
  <div class="wrapper">
    <div class="content">
      <div class="item">
        <div class="item-tit">尺寸:</div>
        <div v-for="(item, index) in size" :key="index" class="item-list">
          {{ item.un }}
          <!-- <el-checkbox v-model="item.packaging" style="margin-right: 10px">
            {{ item.un }}
          </el-checkbox> -->
          长
          <el-input
            v-model="item.length"
            :disabled="isEdit"
            style="width: 90px"
          ></el-input>
          cm
          <span class="x">x</span>
          宽
          <el-input
            v-model="item.width"
            :disabled="isEdit"
            style="width: 90px"
          ></el-input>
          cm
          <span class="x">x</span>
          高
          <el-input
            v-model="item.height"
            :disabled="isEdit"
            style="width: 90px"
          ></el-input>
          cm
        </div>
      </div>
      <div class="item">
        <div class="item-tit">重量:</div>
        <div class="fx">
          <div v-for="(item, index) in size" :key="index" class="item-list">
            <!-- <el-checkbox v-model="item.packaging" style="margin-right: 10px"> -->
            {{ item.un }}
            <!-- </el-checkbox> -->
            <el-input
              v-model="item.weight"
              :disabled="isEdit"
              style="width: 90px"
            ></el-input>
            kg
          </div>
        </div>
      </div>
      <div class="item-tit">产地:</div>
      <!-- 仅查看显示 -->
      <div v-if="isEdit" style="width: 362px">
        <el-table stripe :data="alldata.info.date_arr">
          <el-table-column
            prop="goods_production_date"
            label="生产日期"
            width="250"
            align="center"
          ></el-table-column>
          <el-table-column prop="" label="操作" width="110" align="center">
            <template #default="{ row }">
              <el-button type="text" @click="handleStop(row)">停用</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-tag
        v-for="tag in mineData.info.origin_place"
        :key="tag"
        :closable="!isEdit"
        :disable-transitions="false"
        @close="handleClose(tag)"
      >
        {{ tag }}
      </el-tag>

      <el-input
        v-if="inputVisible"
        ref="saveTagInput"
        v-model="inputValue"
        class="input-new-tag"
        size="medium"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      ></el-input>
      <el-button
        v-else
        v-show="!isEdit"
        class="button-new-tag"
        size="small"
        @click="showInput"
      >
        添加产地
      </el-button>
      <!-- <div class="item">
        <div class="item-tit">产地:</div>
        <div class="item-list">
          <div class="area">
            <el-tag
              v-for="(tag, index) in mineData.info.origin_place"
              :key="index"
              closable
            >
              {{ tag }}
            </el-tag>
          </div>
          <el-input
            v-model="mineData.info.origin_place"
            style="width: 190px"
          ></el-input>
          <el-button style="width: 100px; margin-top: 10px; margin-left: 10px">
            添加
          </el-button>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: 'SizePlace',
    components: {},
    props: {
      alldata: {
        type: Object,
        default: () => {},
        required: true,
      },
      showdialog: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      isEdit: {
        type: Boolean,
        default: () => {
          return false
        },
      },
    },

    data() {
      return {
        inputValue: '',
        inputVisible: false,
        mineData: null,
        // 大小数据
        size: [],
        weight: [
          {
            units: '小包装',
            packaging: false,
            k: '',
          },
          {
            units: '中包装',
            Packaging: '',
            k: '',
          },
          {
            units: '大包装',
            Packaging: '',
            k: '',
          },
        ],
        area: '',
      }
    },
    computed: {},

    watch: {},
    created() {
      console.log('created', this.alldata)
      this.mineData = this.alldata
      this.size = [
        Object.assign(this.mineData.info.units.unit1, { un: '小单位' }),
        Object.assign(this.mineData.info.units.unit2, { un: '中单位' }),
        Object.assign(this.mineData.info.units.unit3, { un: '大单位' }),
      ]
    },
    methods: {
      handleClose(tag) {
        this.mineData.info.origin_place.splice(
          this.mineData.info.origin_place.indexOf(tag),
          1
        )
      },
      showInput() {
        this.inputVisible = true
        this.$nextTick((_) => {
          this.$refs.saveTagInput.$refs.input.focus()
        })
      },
      handleInputConfirm() {
        let inputValue = this.inputValue
        if (inputValue) {
          this.mineData.info.origin_place.push(inputValue)
        }
        this.inputVisible = false
        this.inputValue = ''
      },
      handleStop(row) {
        console.log(row.goods_production_date)
        postAction('/baseAdmin/goods/date-stop', {
          goods_id: this.alldata.info.id,
          goods_production_date: row.goods_production_date,
        }).then((r) => {
          console.log('1')
          this.$message.success('停用成功')
          this.$emit('refresh')
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    min-height: 510px;
    .content {
      margin: 15px;
      .item-tit {
        margin-bottom: 10px;
        font-weight: 600;
      }
      .item-list {
        margin: 15px;
        .x {
          margin: 0 10px;
        }
      }
    }
  }
  .fx {
    display: flex;
    justify-content: flex-start;
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    height: 32px;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 10px;
    line-height: 30px;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
