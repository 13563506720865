var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("div", { staticClass: "item-tit" }, [_vm._v("尺寸:")]),
            _vm._l(_vm.size, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "item-list" },
                [
                  _vm._v(" " + _vm._s(item.un) + " "),
                  _vm._v(" 长 "),
                  _c("el-input", {
                    staticStyle: { width: "90px" },
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: item.length,
                      callback: function ($$v) {
                        _vm.$set(item, "length", $$v)
                      },
                      expression: "item.length",
                    },
                  }),
                  _vm._v(" cm "),
                  _c("span", { staticClass: "x" }, [_vm._v("x")]),
                  _vm._v(" 宽 "),
                  _c("el-input", {
                    staticStyle: { width: "90px" },
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: item.width,
                      callback: function ($$v) {
                        _vm.$set(item, "width", $$v)
                      },
                      expression: "item.width",
                    },
                  }),
                  _vm._v(" cm "),
                  _c("span", { staticClass: "x" }, [_vm._v("x")]),
                  _vm._v(" 高 "),
                  _c("el-input", {
                    staticStyle: { width: "90px" },
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: item.height,
                      callback: function ($$v) {
                        _vm.$set(item, "height", $$v)
                      },
                      expression: "item.height",
                    },
                  }),
                  _vm._v(" cm "),
                ],
                1
              )
            }),
          ],
          2
        ),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "item-tit" }, [_vm._v("重量:")]),
          _c(
            "div",
            { staticClass: "fx" },
            _vm._l(_vm.size, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "item-list" },
                [
                  _vm._v(" " + _vm._s(item.un) + " "),
                  _c("el-input", {
                    staticStyle: { width: "90px" },
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: item.weight,
                      callback: function ($$v) {
                        _vm.$set(item, "weight", $$v)
                      },
                      expression: "item.weight",
                    },
                  }),
                  _vm._v(" kg "),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "item-tit" }, [_vm._v("产地:")]),
        _vm.isEdit
          ? _c(
              "div",
              { staticStyle: { width: "362px" } },
              [
                _c(
                  "el-table",
                  { attrs: { stripe: "", data: _vm.alldata.info.date_arr } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "goods_production_date",
                        label: "生产日期",
                        width: "250",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "操作",
                        width: "110",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleStop(row)
                                      },
                                    },
                                  },
                                  [_vm._v("停用")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3352282325
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._l(_vm.mineData.info.origin_place, function (tag) {
          return _c(
            "el-tag",
            {
              key: tag,
              attrs: { closable: !_vm.isEdit, "disable-transitions": false },
              on: {
                close: function ($event) {
                  return _vm.handleClose(tag)
                },
              },
            },
            [_vm._v(" " + _vm._s(tag) + " ")]
          )
        }),
        _vm.inputVisible
          ? _c("el-input", {
              ref: "saveTagInput",
              staticClass: "input-new-tag",
              attrs: { size: "medium" },
              on: { blur: _vm.handleInputConfirm },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleInputConfirm.apply(null, arguments)
                },
              },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            })
          : _c(
              "el-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isEdit,
                    expression: "!isEdit",
                  },
                ],
                staticClass: "button-new-tag",
                attrs: { size: "small" },
                on: { click: _vm.showInput },
              },
              [_vm._v(" 添加产地 ")]
            ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }