<template>
  <el-dialog
    title="添加商品分组"
    :visible.sync="showDialog"
    width="800px"
    top="15vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <div style="display: flex; align-items: center; margin-bottom: 10px">
        <div style="width: 110px; margin-right: 10px; text-align: right">
          商品组名称:
        </div>
        <el-input v-model="name" style="width: 260px"></el-input>
      </div>
      <el-table stripe :data="sortList">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          width=""
          align="center"
        >
          <template #default="{ row }">
            <div v-if="item.label == '排序'">
              <el-input
                v-model.number="row.sort"
                type="number"
                @change="handlSort($event, row)"
              ></el-input>
            </div>
            <div v-else-if="item.label == '商品名称'">
              <goods-search
                :f-key="row.goods_name"
                @add-rows="addRows"
                @select-goods-all="handleSelect($event, row)"
              ></goods-search>
            </div>
            <div v-else-if="item.label == '规格'">
              {{ row.specs }}
            </div>
            <div v-else></div>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          align="center"
          label="操作"
          fixed="right"
          width="120px"
        >
          <template #default="{ $index, row }">
            <el-button
              type="text"
              @click.native.prevent="handleAdd($index, row)"
            >
              添加
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handleDelete($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" @click="handleSub">提交</el-button>
      <el-button @click="showDialog = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'

  import { postAction } from '@/api/Employee'
  import GoodsSearch from '@/baseComponents/goodsSearch'

  export default {
    name: 'Add',
    components: { GoodsSearch },
    data() {
      return {
        showDialog: false,
        name: '',
        list: [],
        columns: [
          {
            order: 1,
            label: '排序',
            prop: '',
            width: '100',
          },
          {
            order: 2,
            label: '商品名称',
            prop: '',
            width: '',
          },
          {
            order: 3,
            label: '规格',
            prop: 'specs',
            width: '110',
          },
        ],
      }
    },
    computed: {
      sortList() {
        return _.sortBy(this.list, (item) => item.sort_order)
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.addRow()
        } else {
          this.name = ''
          this.list = []
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // /baseAdmin/goods-group/create  新家分组
      // 新建分组
      handleSub() {
        let goods = JSON.parse(JSON.stringify(this.sortList))
        let goods_data = []
        goods.forEach((item) => {
          if (item.goods_id) {
            goods_data.push(item)
          }
        })
        goods_data = goods_data.length == 0 ? '[]' : JSON.stringify(goods_data)
        postAction('/baseAdmin/goods-group/create', {
          name: this.name,
          goods_data,
        }).then((r) => {
          this.$emit('refresh')
          this.showDialog = false
          this.$message.success('添加成功')
        })
      },
      addRow() {
        this.list.push({
          goods_id: null,
          goods_name: '',
          sort: 0,
          sort_order: 0,
          specs: '',
        })
      },
      addRows(val) {
        val.forEach((item) => {
          this.list.push({
            goods_id: item.goods_id,
            goods_name: item.goods_name,
            sort: 0,
            sort_order: 0,
            specs: item.specs,
          })
        })
      },
      handleSelect(val, row) {
        row.specs = val.specs
        row.goods_id = val.goods_id
        row.goods_name = val.goods_name
      },
      handleAdd(index, row) {
        this.addRow()
      },
      handleDelete(index, row) {
        if (this.rlist?.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          if (this.list?.length == 1) {
            this.$message.error('不能删除最后一行')
          } else {
            this.list?.splice(index, 1)
          }
        }
      },
      handlSort(val, row) {
        row.sort_order = val
      },
    },
  }
</script>
<style lang="scss" scoped></style>
