<template>
  <el-dialog
    title="批量修改"
    :visible.sync="showDialog"
    width="500px"
    top="15vh"
    :modal="false"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-table stripe :data="list">
        <el-table-column
          prop="name"
          label="修改项目"
          width="140"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="selectList"
          label="修改为"
          width=""
          align="center"
        >
          <template #default="{ row }">
            <div v-if="row.name == '品类'">
              <el-select v-model="row.value" clearable placeholder="请选择品类">
                <el-option
                  v-for="(i, idx) in classSelectList"
                  :key="idx"
                  :value="i.id"
                  :label="i.class_name"
                ></el-option>
              </el-select>
            </div>

            <div v-else-if="row.name == '品牌'">
              <brand-select
                @brand-select-change="handleChooseBrand"
              ></brand-select>
            </div>
            <div v-else-if="row.name == '保质期'">
              <el-input
                v-model="row.value"
                style="width: 200px"
                placeholder="请输入保质期"
              ></el-input>
            </div>
            <div v-else-if="row.name == '临期预警天数'">
              <el-input
                v-model="row.value"
                style="width: 200px"
                placeholder="请输入临期预警天数"
              ></el-input>
            </div>
            <div v-else>
              <el-select
                v-model="row.value"
                clearable
                style="width: 200px"
                :placeholder="'请选择' + row.name"
              >
                <el-option
                  v-for="(i, idx) in row.selectList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 5px; text-align: right"
      >
        <el-button type="primary" @click="handleSave(10)">
          更新商品价格
        </el-button>
        <el-button type="success" @click="handleSave(11)">
          更新全部商品价格
        </el-button>
        <el-button type="primary" @click="handleSave(0)">保存</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import BrandSelect from '@/baseComponents/brandSelect'
  import { classList, goodsDetailList } from '@/api/selectList'
  import { batchEdit } from '@/api/goodsDetail'
  export default {
    name: '',
    components: { BrandSelect },
    data() {
      return {
        showDialog: false,
        goods_ids: '',
        classSelectList: [],
        stateList: [],
        list: [
          {
            name: '品类',
            value: '',
            isSelect: true,
            selectList: [],
          },
          {
            name: '品牌',
            value: '',
            isSelect: true,
            selectList: [],
          },
          {
            name: '通路',
            value: '',
            isSelect: true,
            selectList: [],
          },
          // 销售价可改 1不可改 2可改
          {
            name: '可改销售价',
            value: '',
            isSelect: true,
            selectList: [
              {
                id: 1,
                name: '不可改',
              },
              {
                id: 2,
                name: '可改',
              },
            ],
          },
          // 退货可改 1不可改 2可改
          {
            name: '可改退货价',
            value: '',
            isSelect: true,
            selectList: [
              {
                id: 1,
                name: '不可改',
              },
              {
                id: 2,
                name: '可改',
              },
            ],
          },
          // 默认单位 1小单位2中单位3大单位
          {
            name: '默认单位',
            value: '',
            isSelect: true,
            selectList: [
              {
                id: 1,
                name: '小单位',
              },
              {
                id: 2,
                name: '中单位',
              },
              {
                id: 3,
                name: '大单位	',
              },
            ],
          },
          // 下单填写生产日期 1必填 0非必填
          {
            name: '下单填写生成日期',
            value: '',
            isSelect: true,
            selectList: [
              {
                id: 1,
                name: '必填',
              },
              {
                id: 2,
                name: '非必填',
              },
            ],
          },
          {
            name: '保质期',
            value: '',
            isSelect: false,
            selectList: [],
            baozhiqu: '',
          },
          {
            name: '临期预警天数',
            value: '',
            isSelect: false,
            linqi: '',
            selectList: [],
          },
        ],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.list.forEach((i) => {
            i.value = ''
          })
        }
      },
    },
    created() {},
    mounted() {
      this.getClassSelectList()
      this.getSelectList()
    },
    methods: {
      handleChooseBrand(val) {
        this.list[1].value = val
      },
      // 品类下拉
      async getClassSelectList() {
        let { data, msg, code } = await classList()
        this.classSelectList = data
      },
      // 通路下拉
      async getSelectList() {
        let { data, msg, code } = await goodsDetailList()
        // 通路下拉
        this.list[2].selectList = data.sale_type
      },
      handleSave(type) {
        let data = {
          goods_ids: this.goods_ids, //产品ID 用,分隔
          edit_objs: [], //
          class_id: '', //品类ID
          brand_id: '', //品牌ID
          sale_type: '', //通路ID
          sell_price_change: '', //销售价可改 1不可改 2可改
          return_price_change: '', //退货可改  1不可改 2可改
          unit_type: '', //默认单位 1小单位2中单位3大单位
          must_choose_date: '', //下单填写生产日期  1必填 0非必填
          expire_day: '', //保质期
          expire_day_stock: '', //预警天数
        }
        this.list.forEach((item, index) => {
          if (item.value) {
            data.edit_objs.push(index + 1)
          }
        })
        data.class_id = this.list[0].value
        data.brand_id = this.list[1].value
        data.sale_type = this.list[2].value
        data.sell_price_change = this.list[3].value
        data.return_price_change = this.list[4].value
        data.unit_type = this.list[5].value
        data.must_choose_date = this.list[6].value
        data.expire_day = this.list[7].value
        data.expire_day_stock = this.list[8].value
        data.edit_objs = data.edit_objs.join()
        if (type) {
          data.edit_objs = type
        }
        console.log('data', data)
        batchEdit(data).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.$emit('settabelist')
          if (!type) this.close()
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
