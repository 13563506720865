var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "批量修改",
        visible: _vm.showDialog,
        width: "500px",
        top: "15vh",
        modal: false,
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.list } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "修改项目",
                  width: "140",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "selectList",
                  label: "修改为",
                  width: "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.name == "品类"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择品类",
                                    },
                                    model: {
                                      value: row.value,
                                      callback: function ($$v) {
                                        _vm.$set(row, "value", $$v)
                                      },
                                      expression: "row.value",
                                    },
                                  },
                                  _vm._l(
                                    _vm.classSelectList,
                                    function (i, idx) {
                                      return _c("el-option", {
                                        key: idx,
                                        attrs: {
                                          value: i.id,
                                          label: i.class_name,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : row.name == "品牌"
                          ? _c(
                              "div",
                              [
                                _c("brand-select", {
                                  on: {
                                    "brand-select-change":
                                      _vm.handleChooseBrand,
                                  },
                                }),
                              ],
                              1
                            )
                          : row.name == "保质期"
                          ? _c(
                              "div",
                              [
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: { placeholder: "请输入保质期" },
                                  model: {
                                    value: row.value,
                                    callback: function ($$v) {
                                      _vm.$set(row, "value", $$v)
                                    },
                                    expression: "row.value",
                                  },
                                }),
                              ],
                              1
                            )
                          : row.name == "临期预警天数"
                          ? _c(
                              "div",
                              [
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: { placeholder: "请输入临期预警天数" },
                                  model: {
                                    value: row.value,
                                    callback: function ($$v) {
                                      _vm.$set(row, "value", $$v)
                                    },
                                    expression: "row.value",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择" + row.name,
                                    },
                                    model: {
                                      value: row.value,
                                      callback: function ($$v) {
                                        _vm.$set(row, "value", $$v)
                                      },
                                      expression: "row.value",
                                    },
                                  },
                                  _vm._l(row.selectList, function (i, idx) {
                                    return _c("el-option", {
                                      key: idx,
                                      attrs: { value: i.id, label: i.name },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "5px", "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave(10)
                    },
                  },
                },
                [_vm._v(" 更新商品价格 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave(11)
                    },
                  },
                },
                [_vm._v(" 更新全部商品价格 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave(0)
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }