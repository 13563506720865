<template>
  <div class="wrapper">
    <el-checkbox v-model="checked" :disabled="isEdit">
      多口味子商品独立核算库存
    </el-checkbox>
    <el-popconfirm
      title="说明：当商品有订单后，不能再修改子商品是否独立核算库存货 简介：多口味主要用于同一品规商品，有多种口味时使用 举例：300ml*12的某品牌茶饮，有多种口味，有红茶、绿茶、乌龙茶等，此时可先创建茶饮父商品，再添加红茶、绿茶、乌龙茶子商品 注意：当子商品独立核算库存时，父商品不再是独立商品，不可采购、盘点、下单，但可用于创建搭赠、预存等活动；子商品不独立核算库存时，只有父商品可采购、盘点，子商品和父商品都可用于下单和创建活动，子商品共用父商品库存"
    >
      <el-button slot="reference" style="width: auto; border: none">
        <i class="el-icon-warning" style="font-size: 22px"></i>
      </el-button>
    </el-popconfirm>

    <div class="table">
      <el-table stripe :data="tableData" border>
        <el-table-column
          v-for="(item, index) in columnsInfo"
          :key="index"
          align="center"
          :label="item.label"
          width=""
        >
          <template #default="{ row }">
            <template v-if="1">
              <el-input v-model="row[item.prop]" :disabled="isEdit"></el-input>
            </template>
            <template v-else>
              {{ row[item.prop] }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!isEdit"
          align="center"
          width="auto"
          label="操作"
        >
          <template #default="{ $index, row }">
            <el-button type="text" @click="addRow(row)">添加</el-button>
            <el-button type="text" @click="delRow($index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  import _ from 'lodash'
  export default {
    name: 'MoreInfo',
    components: {},

    props: {
      alldata: {
        type: Object,
        default: () => {},
        required: true,
      },
      showdialog: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      isEdit: {
        type: Boolean,
        default: () => {
          return false
        },
      },
    },
    data() {
      return {
        checked: false,
        // 表格
        checkList: ['口味', '小单位条码', '中单位条码', '大单位条码', '编码'],
        columns: [
          {
            order: 1,
            label: '口味',
            prop: 'attr',
            width: 'auto',
          },
          {
            order: 2,
            label: '小单位条码',
            prop: 'small_unit_code',
            width: 'auto',
          },
          {
            order: 3,
            label: '中单位条码',
            prop: 'middle_unit_code',
            width: 'auto',
          },
          {
            order: 4,
            label: '大单位条码',
            prop: 'big_unit_code',
            width: 'auto',
          },
          {
            order: 5,
            label: '编码',
            prop: 'child_code',
            width: 'auto',
          },
        ],
        tableData: [],
      }
    },
    computed: {
      columnsInfo() {
        let data = []
        this.checkList.forEach((checkItem) => {
          data.push(this.columns.filter((item) => item.label === checkItem)[0])
        })
        return _.sortBy(data, (item) => item.order)
      },
    },
    mounted() {
      this.tableData = this.alldata.info.child
      console.log('table')
      console.log(this.tableData)
      if (this.tableData.length == 0) {
        this.addRowData()
      }
      // 单选框
      this.checked = this.alldata.info.is_child_Indep == 2 ? true : false
    },
    methods: {
      addRowData() {
        if (!this.isEdit) {
          this.tableData.push({
            edit: true,
            attr: '',
            small_unit_code: '',
            middle_unit_code: '',
            big_unit_code: '',
            child_code: '',
          })
        }
      },
      addRow(row) {
        this.addRowData()
      },
      delRow(index) {
        if (this.tableData.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.tableData.splice(index, 1)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    min-height: 510px;
  }
</style>
