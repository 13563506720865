var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: _vm.title,
        top: "5vh",
        visible: _vm.showDialog,
        width: "95%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.showLoading,
              expression: "showLoading",
            },
          ],
          staticClass: "wrapper",
        },
        [
          _vm.loadBaseOK
            ? _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "基础信息", name: "first" } },
                    [
                      _c("base-info", {
                        ref: "baseInfo",
                        attrs: {
                          showdialog: _vm.showDialog,
                          alldata: _vm.alldata,
                          "is-edit": _vm.isEdit == 0 ? true : false,
                          tit: _vm.title,
                        },
                        on: {
                          "update:showdialog": function ($event) {
                            _vm.showDialog = $event
                          },
                          "update:alldata": function ($event) {
                            _vm.alldata = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "尺寸重量产地", name: "second" } },
                    [
                      _c("size-place", {
                        ref: "sizePlace",
                        attrs: {
                          showdialog: _vm.showDialog,
                          alldata: _vm.alldata,
                          "is-edit": _vm.isEdit == 0 ? true : false,
                        },
                        on: {
                          "update:showdialog": function ($event) {
                            _vm.showDialog = $event
                          },
                          "update:alldata": function ($event) {
                            _vm.alldata = $event
                          },
                          refresh: _vm.fetchData,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "图文信息", name: "third" } },
                    [
                      _c("pic-info", {
                        ref: "picInfo",
                        attrs: {
                          showdialog: _vm.showDialog,
                          alldata: _vm.alldata,
                          "is-edit": _vm.isEdit == 0 ? true : false,
                        },
                        on: {
                          "update:showdialog": function ($event) {
                            _vm.showDialog = $event
                          },
                          "update:alldata": function ($event) {
                            _vm.alldata = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "多口味", name: "fourth" } },
                    [
                      _c("more-info", {
                        ref: "moreInfo",
                        attrs: {
                          showdialog: _vm.showDialog,
                          alldata: _vm.alldata,
                          "is-edit": _vm.isEdit == 0 ? true : false,
                        },
                        on: {
                          "update:showdialog": function ($event) {
                            _vm.showDialog = $event
                          },
                          "update:alldata": function ($event) {
                            _vm.alldata = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.alldata.info && _vm.title == "修改商品"
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "margin-top": "10px" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "warning" },
                          on: { click: _vm.getChangeTime },
                        },
                        [_vm._v(" 更新商品价格 ")]
                      ),
                      _vm._v(" 更新时间: "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.alldata.info.price_change_time)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.isEdit
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.save },
                            },
                            [_vm._v("保存")]
                          )
                        : _vm._e(),
                      _c("el-button", { on: { click: _vm.close } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _c(
                "div",
                { staticStyle: { "margin-top": "5px", "text-align": "right" } },
                [
                  _vm.isEdit
                    ? _c(
                        "el-button",
                        { attrs: { type: "primary" }, on: { click: _vm.save } },
                        [_vm._v("保存")]
                      )
                    : _vm._e(),
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("关闭"),
                  ]),
                ],
                1
              ),
          _vm.title == "修改商品"
            ? _c("div", { staticStyle: { "margin-top": "5px" } }, [
                _vm._v(" 说明:商品调价后请点此按钮,开单时即可执行新价格. "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }