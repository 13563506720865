var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mineData.info
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "wrapBox" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    disabled: _vm.isEdit,
                    model: _vm.mineData.info,
                    "label-width": "120px",
                    inline: true,
                    rules: _vm.rules,
                    "inline-message": "",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "goods_name", label: "商品名称:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入商品名称" },
                        model: {
                          value: _vm.mineData.info.goods_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.mineData.info, "goods_name", $$v)
                          },
                          expression: "mineData.info.goods_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "specs", label: "规格:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入规格" },
                        model: {
                          value: _vm.mineData.info.specs,
                          callback: function ($$v) {
                            _vm.$set(_vm.mineData.info, "specs", $$v)
                          },
                          expression: "mineData.info.specs",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "easy_code", label: "助记码:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入助记码" },
                        model: {
                          value: _vm.mineData.info.easy_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.mineData.info, "easy_code", $$v)
                          },
                          expression: "mineData.info.easy_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "class_id", label: "品类:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "请选择类别" },
                          model: {
                            value: _vm.mineData.info.class_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.mineData.info, "class_id", $$v)
                            },
                            expression: "mineData.info.class_id",
                          },
                        },
                        _vm._l(_vm.mineData.init.class, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.class_name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "brand_id", label: "品牌:" } },
                    [
                      _c("brand-select", {
                        ref: "brandSelect",
                        attrs: {
                          "brand-id": Number(_vm.mineData.info.brand_id),
                        },
                        on: { "brand-select-change": _vm.setBrand },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "expire_day_text", label: "保质期:" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { type: "flex", justify: "space-between" } },
                        [
                          _c(
                            "el-col",
                            [
                              _c("el-input", {
                                staticStyle: { width: "50px" },
                                attrs: { placeholder: "" },
                                on: { change: _vm.handlerexpireday },
                                model: {
                                  value: _vm.expire_day,
                                  callback: function ($$v) {
                                    _vm.expire_day = $$v
                                  },
                                  expression: "expire_day",
                                },
                              }),
                              _c("span", [_vm._v("年")]),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            [
                              _c("el-input", {
                                staticStyle: { width: "50px" },
                                attrs: { placeholder: "" },
                                on: { change: _vm.handlerexpireday },
                                model: {
                                  value: _vm.expire_day1,
                                  callback: function ($$v) {
                                    _vm.expire_day1 = $$v
                                  },
                                  expression: "expire_day1",
                                },
                              }),
                              _c("span", [_vm._v("月")]),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            [
                              _c("el-input", {
                                staticStyle: { width: "50px" },
                                attrs: { placeholder: "" },
                                on: { change: _vm.handlerexpireday },
                                model: {
                                  value: _vm.expire_day2,
                                  callback: function ($$v) {
                                    _vm.expire_day2 = $$v
                                  },
                                  expression: "expire_day2",
                                },
                              }),
                              _c("span", [_vm._v("天")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "expire_day_stock",
                        label: "临期预警天数:",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入临期预警天数" },
                        model: {
                          value: _vm.mineData.info.expire_day_stock,
                          callback: function ($$v) {
                            _vm.$set(_vm.mineData.info, "expire_day_stock", $$v)
                          },
                          expression: "mineData.info.expire_day_stock",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { prop: "paht", label: "通路:" },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.path,
                            callback: function ($$v) {
                              _vm.path = $$v
                            },
                            expression: "path",
                          },
                        },
                        [
                          _c("el-checkbox", {
                            attrs: { label: "线下", name: "paht" },
                          }),
                          _c("el-checkbox", {
                            attrs: { label: "网销", name: "paht" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "form2",
                  attrs: {
                    model: _vm.mineData,
                    rules: _vm.rules2,
                    disabled: _vm.isEdit,
                    "label-width": "120px",
                    inline: "",
                    "inline-message": "",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        prop: "init.unit.unit1.selected",
                        label: "小单位:",
                      },
                    },
                    [
                      _c("div", { staticClass: "form-item-wrap" }, [
                        _c(
                          "div",
                          { staticClass: "form-item-left" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择小单位",
                                  clearable: "",
                                },
                                on: {
                                  change: _vm.xUnitChange,
                                  focus: _vm.handlerfocus,
                                },
                                model: {
                                  value: _vm.mineData.init.unit.unit1.selected,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.mineData.init.unit.unit1,
                                      "selected",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "mineData.init.unit.unit1.selected",
                                },
                              },
                              _vm._l(
                                _vm.mineData.init.unit.unit1.list,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.dealer_unit_id,
                                    attrs: {
                                      label: item.unit_name,
                                      value: item.dealer_unit_id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { "true-label": 2, "false-label": 1 },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkChange($event, 1)
                                  },
                                },
                                model: {
                                  value:
                                    _vm.mineData.info.units.unit1.is_default,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.mineData.info.units.unit1,
                                      "is_default",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "mineData.info.units.unit1.is_default",
                                },
                              },
                              [_vm._v(" 默认 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: { type: "primary" },
                                on: { click: _vm.setUnit },
                              },
                              [_vm._v(" 设置单位 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-item-right" },
                          [
                            _c("span", [_vm._v("条码:")]),
                            _c("el-input", {
                              model: {
                                value: _vm.mineData.info.units.unit1.unit_code,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.mineData.info.units.unit1,
                                    "unit_code",
                                    $$v
                                  )
                                },
                                expression:
                                  "mineData.info.units.unit1.unit_code",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mineData.init.unit.unit1.selected !== "",
                          expression:
                            "mineData.init.unit.unit1.selected !== ''",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        prop: "init.unit.unit3.selected",
                        label: "大单位:",
                      },
                    },
                    [
                      _c("div", { staticClass: "form-item-wrap" }, [
                        _c(
                          "div",
                          { staticClass: "form-item-left" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择大单位",
                                  clearable: "",
                                },
                                on: {
                                  change: _vm.dUnitChange,
                                  focus: _vm.handlerfocus,
                                },
                                model: {
                                  value: _vm.mineData.init.unit.unit3.selected,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.mineData.init.unit.unit3,
                                      "selected",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "mineData.init.unit.unit3.selected",
                                },
                              },
                              _vm._l(
                                _vm.mineData.init.unit.unit3.list,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.dealer_unit_id,
                                    attrs: {
                                      label: item.unit_name,
                                      value: item.dealer_unit_id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { "false-label": 1, "true-label": 2 },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkChange($event, 3)
                                  },
                                },
                                model: {
                                  value:
                                    _vm.mineData.info.units.unit3.is_default,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.mineData.info.units.unit3,
                                      "is_default",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "mineData.info.units.unit3.is_default",
                                },
                              },
                              [_vm._v(" 默认 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-item-right" },
                          [
                            _c("span", [_vm._v("条码:")]),
                            _c("el-input", {
                              model: {
                                value: _vm.mineData.info.units.unit3.unit_code,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.mineData.info.units.unit3,
                                    "unit_code",
                                    $$v
                                  )
                                },
                                expression:
                                  "mineData.info.units.unit3.unit_code",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.mineData.init.unit.unit1.selected !== "" &&
                            _vm.mineData.init.unit.unit3.selected !== "",
                          expression:
                            "\n            mineData.init.unit.unit1.selected !== '' &&\n            mineData.init.unit.unit3.selected !== ''\n          ",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        prop: "init.unit.unit2.selected",
                        label: "中单位:",
                      },
                    },
                    [
                      _c("div", { staticClass: "form-item-wrap" }, [
                        _c(
                          "div",
                          { staticClass: "form-item-left" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择中单位",
                                  clearable: "",
                                },
                                on: {
                                  change: _vm.zUnitChange,
                                  focus: _vm.handlerfocus,
                                },
                                model: {
                                  value: _vm.mineData.init.unit.unit2.selected,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.mineData.init.unit.unit2,
                                      "selected",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "mineData.init.unit.unit2.selected",
                                },
                              },
                              _vm._l(
                                _vm.mineData.init.unit.unit2.list,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.dealer_unit_id,
                                    attrs: {
                                      label: item.unit_name,
                                      value: item.dealer_unit_id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { "false-label": 1, "true-label": 2 },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkChange($event, 2)
                                  },
                                },
                                model: {
                                  value:
                                    _vm.mineData.info.units.unit2.is_default,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.mineData.info.units.unit2,
                                      "is_default",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "mineData.info.units.unit2.is_default",
                                },
                              },
                              [_vm._v(" 默认 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-item-right" },
                          [
                            _c("span", [_vm._v("条码:")]),
                            _c("el-input", {
                              model: {
                                value: _vm.mineData.info.units.unit2.unit_code,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.mineData.info.units.unit2,
                                    "unit_code",
                                    $$v
                                  )
                                },
                                expression:
                                  "mineData.info.units.unit2.unit_code",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "form3",
                  attrs: {
                    disabled: _vm.isEdit,
                    model: _vm.mineData.info.units,
                    rules: _vm.rules3,
                    "label-width": "120px",
                    inline: "",
                    "inline-message": "",
                  },
                },
                [
                  _vm.mineData.init.unit.unit1.selected !== "" &&
                  _vm.mineData.init.unit.unit3.selected !== ""
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "unit3.goods_cv",
                            label: "1" + _vm.numtoval(3) + "=",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "65px",
                              "margin-right": "5px",
                            },
                            model: {
                              value: _vm.mineData.info.units.unit3.goods_cv,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.mineData.info.units.unit3,
                                  "goods_cv",
                                  $$v
                                )
                              },
                              expression: "mineData.info.units.unit3.goods_cv",
                            },
                          }),
                          _c("span", [_vm._v(_vm._s(_vm.numtoval(1)))]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.mineData.init.unit.unit1.selected !== "" &&
                  _vm.mineData.init.unit.unit2.selected !== "" &&
                  _vm.mineData.init.unit.unit3.selected !== ""
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "unit2.goods_cv",
                            label: "1" + _vm.numtoval(2) + "=",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "65px",
                              "margin-right": "5px",
                            },
                            model: {
                              value: _vm.mineData.info.units.unit2.goods_cv,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.mineData.info.units.unit2,
                                  "goods_cv",
                                  $$v
                                )
                              },
                              expression: "mineData.info.units.unit2.goods_cv",
                            },
                          }),
                          _c("span", [_vm._v(_vm._s(_vm.numtoval(1)))]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "checkBox" },
            [
              _c("el-checkbox", {
                attrs: {
                  disabled: _vm.isEdit,
                  label: "自动换算价格",
                  "true-label": 2,
                  "false-label": 1,
                },
                model: {
                  value: _vm.middleCheck.a,
                  callback: function ($$v) {
                    _vm.$set(_vm.middleCheck, "a", $$v)
                  },
                  expression: "middleCheck.a",
                },
              }),
              _c("el-checkbox", {
                attrs: {
                  disabled: _vm.isEdit,
                  "true-label": 1,
                  "false-label": 0,
                  label: "下单时需选生产日期",
                },
                model: {
                  value: _vm.middleCheck.b,
                  callback: function ($$v) {
                    _vm.$set(_vm.middleCheck, "b", $$v)
                  },
                  expression: "middleCheck.b",
                },
              }),
              _c("el-checkbox", {
                attrs: {
                  "true-label": 2,
                  "false-label": 1,
                  label: "下单时销售价可修改",
                  disabled: _vm.isEdit,
                },
                model: {
                  value: _vm.middleCheck.c,
                  callback: function ($$v) {
                    _vm.$set(_vm.middleCheck, "c", $$v)
                  },
                  expression: "middleCheck.c",
                },
              }),
              _c("el-checkbox", {
                attrs: {
                  disabled: _vm.isEdit,
                  "true-label": 2,
                  "false-label": 1,
                  label: "下单时退货价可修改",
                },
                model: {
                  value: _vm.middleCheck.d,
                  callback: function ($$v) {
                    _vm.$set(_vm.middleCheck, "d", $$v)
                  },
                  expression: "middleCheck.d",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  ref: "table",
                  attrs: { stripe: "", data: _vm.tableData, border: "" },
                },
                _vm._l(_vm.columns, function (item, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: item.prop,
                      align: item.align,
                      label: item.label,
                      width: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        item.prop === "units"
                          ? {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  $index == 0
                                    ? [
                                        _vm._v(
                                          " " + _vm._s(row.unit_name) + " "
                                        ),
                                      ]
                                    : $index == 1
                                    ? [
                                        _vm._v(
                                          " " + _vm._s(row.unit_name) + " "
                                        ),
                                      ]
                                    : $index == 2
                                    ? [
                                        _vm._v(
                                          " " + _vm._s(row.unit_name) + " "
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            }
                          : {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                var column = ref.column
                                var row = ref.row
                                return [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isEdit },
                                    on: {
                                      input: function ($event) {
                                        return _vm.priceInput(
                                          $event,
                                          row,
                                          $index,
                                          column
                                        )
                                      },
                                    },
                                    model: {
                                      value: row[item.prop],
                                      callback: function ($$v) {
                                        _vm.$set(row, item.prop, $$v)
                                      },
                                      expression: "row[item.prop]",
                                    },
                                  }),
                                ]
                              },
                            },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
              _vm.costshow
                ? _c(
                    "el-table",
                    {
                      staticStyle: { width: "50%", "margin-top": "50px" },
                      attrs: { data: _vm.costtable },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "new_price",
                          label: "小单位成本价",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "create_at",
                          label: "时间",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark",
                          label: "操作记录",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }