var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-checkbox",
        {
          attrs: { disabled: _vm.isEdit },
          model: {
            value: _vm.checked,
            callback: function ($$v) {
              _vm.checked = $$v
            },
            expression: "checked",
          },
        },
        [_vm._v(" 多口味子商品独立核算库存 ")]
      ),
      _c(
        "el-popconfirm",
        {
          attrs: {
            title:
              "说明：当商品有订单后，不能再修改子商品是否独立核算库存货 简介：多口味主要用于同一品规商品，有多种口味时使用 举例：300ml*12的某品牌茶饮，有多种口味，有红茶、绿茶、乌龙茶等，此时可先创建茶饮父商品，再添加红茶、绿茶、乌龙茶子商品 注意：当子商品独立核算库存时，父商品不再是独立商品，不可采购、盘点、下单，但可用于创建搭赠、预存等活动；子商品不独立核算库存时，只有父商品可采购、盘点，子商品和父商品都可用于下单和创建活动，子商品共用父商品库存",
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "auto", border: "none" },
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c("i", {
                staticClass: "el-icon-warning",
                staticStyle: { "font-size": "22px" },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.tableData, border: "" } },
            [
              _vm._l(_vm.columnsInfo, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: { align: "center", label: item.label, width: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            1
                              ? [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isEdit },
                                    model: {
                                      value: row[item.prop],
                                      callback: function ($$v) {
                                        _vm.$set(row, item.prop, $$v)
                                      },
                                      expression: "row[item.prop]",
                                    },
                                  }),
                                ]
                              : [_vm._v(" " + _vm._s(row[item.prop]) + " ")],
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              !_vm.isEdit
                ? _c("el-table-column", {
                    attrs: { align: "center", width: "auto", label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var $index = ref.$index
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addRow(row)
                                    },
                                  },
                                },
                                [_vm._v("添加")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delRow($index)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      377275655
                    ),
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }