var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.form, "label-width": "80px", inline: true } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "class" } },
            [
              _c("class-select", {
                ref: "classSelect",
                attrs: { "popper-class": "select-idx" },
                on: { "class-select-change": _vm.chooseClass },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "brand" } },
            [
              _c("brand-select", {
                ref: "brandSelect",
                staticStyle: { width: "160px" },
                attrs: { "popper-class": "select-idx" },
                on: { "brand-select-change": _vm.chooseBrand },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "sale_type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: {
                    placeholder: "通路",
                    "popper-class": "select-idx",
                    clearable: "",
                  },
                  model: {
                    value: _vm.queryTableForm.sale_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryTableForm, "sale_type", $$v)
                    },
                    expression: "queryTableForm.sale_type",
                  },
                },
                _vm._l(_vm.stateList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "is_img" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: {
                    placeholder: "图片",
                    "popper-class": "select-idx",
                    clearable: "",
                  },
                  model: {
                    value: _vm.queryTableForm.is_img,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryTableForm, "is_img", $$v)
                    },
                    expression: "queryTableForm.is_img",
                  },
                },
                _vm._l(_vm.picList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "goods_group" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: {
                    "popper-class": "select-idx",
                    placeholder: "商品分组",
                    clearable: "",
                  },
                  model: {
                    value: _vm.queryTableForm.goods_group,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryTableForm, "goods_group", $$v)
                    },
                    expression: "queryTableForm.goods_group",
                  },
                },
                _vm._l(_vm.groupList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "searchVal" } },
            [
              _c("goods-search", {
                ref: "goodsSearch",
                attrs: {
                  "is-table": false,
                  "popper-class": "select-idx selectDC",
                  "goods-list-obj": { is_set: 1 },
                },
                on: {
                  "input-goods": _vm.setkeyword,
                  "select-search-goods": _vm.setkeyword,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "checked" } },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { "true-label": 1, "false-label": 0 },
                  model: {
                    value: _vm.queryTableForm.is_close,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryTableForm, "is_close", $$v)
                    },
                    expression: "queryTableForm.is_close",
                  },
                },
                [_vm._v(" 已封存 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "checked" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "checked" } },
            [
              _c("el-button", { on: { click: _vm.handleImport } }, [
                _vm._v("导入"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "checked" } },
            [
              _c("el-button", { on: { click: _vm.handleImportMore } }, [
                _vm._v("导入多口味"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "checked" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "checked" } },
            [
              _c("el-button", { on: { click: _vm.handleExportMore } }, [
                _vm._v("导出多口味"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "checked" } },
            [
              _c("el-button", { on: { click: _vm.handleExportList } }, [
                _vm._v("按列表导出"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "checked" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addGoods } },
                [_vm._v("添加商品")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "checked" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleGroup } },
                [_vm._v("自定义商品组")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tableWrap" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "tableSort",
              attrs: { stripe: "", data: _vm.list, "row-key": "id" },
              on: { "selection-change": _vm.handleTableChange },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  type: "selection",
                  width: "50",
                  "reserve-selection": true,
                },
              }),
              _vm._l(_vm.finallyColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    align: item.align,
                    label: item.label,
                    width: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      item.label === "排序"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.blur_(row, row.sort)
                                    },
                                  },
                                  model: {
                                    value: row.sort,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        row,
                                        "sort",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "row.sort",
                                  },
                                }),
                              ]
                            },
                          }
                        : item.label == "可改价"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content:
                                        row.sell_price_change == false
                                          ? "点击开启"
                                          : "点击关闭",
                                      enterable: false,
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      on: {
                                        change: function ($event) {
                                          return _vm.handlerSwitch(row)
                                        },
                                      },
                                      model: {
                                        value: row.sell_price_change,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            row,
                                            "sell_price_change",
                                            $$v
                                          )
                                        },
                                        expression: "row.sell_price_change",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  align: "center",
                  label: "操作",
                  fixed: "right",
                  "min-width": "160px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleCheck($index, row)
                              },
                            },
                          },
                          [_vm._v(" 查看 ")]
                        ),
                        row.is_close != 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.editRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 修改 ")]
                            )
                          : _vm._e(),
                        row.is_close != 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.copyRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            )
                          : _vm._e(),
                        row.is_close == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.saveRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 封存 ")]
                            )
                          : _vm._e(),
                        row.is_close == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.qiyong($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 启用 ")]
                            )
                          : _vm._e(),
                        row.is_close == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "page" },
            [
              _c(
                "el-row",
                { staticStyle: { "margin-top": "15px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "warning" },
                          on: { click: _vm.handleBatchEdit },
                        },
                        [_vm._v(" 批量修改 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.queryTableForm.pageNo,
                          layout: _vm.layout,
                          "page-size": _vm.queryTableForm.pageSize,
                          total: _vm.total,
                          background: "",
                        },
                        on: {
                          "current-change": _vm.handleCurrentChange,
                          "size-change": _vm.handleSizeChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("table-edit", { ref: "tableEdit", on: { refresh: _vm.fetchData } }),
      _c("batch-edit", {
        ref: "batchEdit",
        on: { refresh: _vm.fetchData, settabelist: _vm.settabelist },
      }),
      _c("import-tpl", { ref: "importTpl" }),
      _c("group", {
        ref: "group",
        on: {
          refresh: function () {
            _vm.queryTableForm.goods_group = ""
            _vm.fetchData()
            _vm.getSelectList()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }