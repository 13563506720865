<template>
  <div class="wrapper">
    <div class="upPic">
      <!-- <el-upload
        action="http://test.admin.sss.cn/baseAdmin/common/upload-image"
        list-type="picture-card"
        name="files"
        accept=".png, .jpg, .jpeg, .gif, .bmp, .jfif"
        :headers="headers"
        :on-preview="handlePictureCardPreview"
        :before-upload="loadBefore"
        :on-progress="onloadEvent"
        :on-remove="handleRemove"
        :on-success="onloadSucc"
        :on-error="onloadError"
        :on-change="onChange"
        :file-list="fileList"
      >
        <i class="el-icon-plus"></i>
      </el-upload> -->
      <el-upload
        v-show="!isEdit"
        :action="baseURL + '/baseAdmin/common/upload-image'"
        list-type="picture-card"
        name="files"
        accept=".png, .jpg, .jpeg, .gif, .bmp, .jfif"
        :headers="headers"
        :before-upload="loadBefore"
        :on-success="onloadSucc"
        :on-error="onloadError"
        :on-change="onChange"
        :file-list="fileList"
        :disabled="isEdit"
      >
        <i class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <div v-if="file.is_default == '1'" class="picD">默认</div>
          <!-- <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" /> -->
          <el-image fit="fill" :src="file.url"></el-image>
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              v-show="!isEdit"
              class="el-upload-list__item-preview"
              @click="setDefault(file)"
            >
              <i class="el-icon-picture-outline"></i>
            </span>
            <span
              v-if="!disabled"
              v-show="!isEdit"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <div v-show="isEdit" class="df">
        <div v-for="(file, index) in fileList" :key="index">
          <!-- <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" /> -->
          <div style="position: relative; margin-left: 10px">
            <el-image
              style="width: 200px; height: 200px"
              fit="fill"
              :src="file.url"
              :preview-src-list="picUrl"
            ></el-image>
            <span v-if="file.is_default == '1'" class="picD">默认</span>
          </div>
        </div>
      </div>
      <!-- 富文本html -->
      <div v-show="isEdit" v-html="alldata.info.content"></div>
      <el-dialog
        :modal="false"
        top="5vh"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
      >
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
    <div v-show="!isEdit" class="tip">
      <el-alert
        title="1、本地上传图片大小不能超过5MB,建议图片尺寸750X750像素，您可使用美图秀秀等图片编辑软件修改尺寸"
        type="warning"
      ></el-alert>
      <el-alert
        title="2、上传图片支持gif、jpg、png、jpeg、bmp、jfif"
        type="warning"
      ></el-alert>
    </div>
    <div v-show="!isEdit" id="demo1"></div>
  </div>
</template>
<script>
  import E from 'wangeditor'
  import { baseURL } from '@/config'
  export default {
    name: 'PicInfo',
    components: {},
    props: {
      alldata: {
        type: Object,
        default: () => {},
        required: true,
      },
      showdialog: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      isEdit: {
        type: Boolean,
        default: () => {
          return false
        },
      },
    },
    data() {
      return {
        mineData: null,
        editor: null,
        editorData: '',
        disabled: false,
        dialogImageUrl: '',
        dialogVisible: false,
        headers: { token: '' },
        fileList: [],
        content: '',
      }
    },
    computed: {
      baseURL() {
        return baseURL
      },
      picUrl() {
        let arr = []
        this.fileList.forEach((item) => {
          arr.push(item.url)
        })
        return arr
      },
    },
    watch: {
      showdialog(val) {
        if (val) {
          console.log('token', this.$token)
        }
      },
    },
    created() {
      this.headers.token = this.$store.getters['user/accessToken']
      this.mineData = this.alldata
      console.log(this.mineData)
      this.fileList = this.mineData.info.goods_imgs
      console.log(this.fileList)
    },
    mounted() {
      // 富文本
      const editor = new E(`#demo1`)
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.editorData = newHtml
      }
      // 配置 server 接口地址
      let uploadUrl = baseURL + '/baseAdmin/common/upload-image-wangeditor'
      console.log('uploadUrl', uploadUrl)
      editor.config.uploadImgServer = uploadUrl

      // 创建编辑器
      editor.create()
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024 // 5M
      editor.config.uploadFileName = 'files'
      editor.config.uploadImgHeaders = this.headers
      editor.config.uploadImgHooks = {
        // 图片上传并返回了结果，图片插入已成功
        success: function (xhr) {
          console.log('success', xhr)
        },
        // 图片上传并返回了结果，但图片插入时出错了
        fail: function (xhr, editor, resData) {
          console.log('fail', resData)
        },
        // 上传图片出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          console.log('error', xhr, resData)
        },
        // 上传图片超时
        timeout: function (xhr) {
          console.log('timeout')
        },
      }
      this.editor = editor
      this.content = this.mineData.info.content
      this.editor.txt.html(this.content)
    },
    methods: {
      // 设置默认图片
      setDefault(file) {
        this.fileList.forEach((item, index) => {
          this.fileList[index].is_default = '0'
          if (item.uid == file.uid) {
            this.fileList[index].is_default = '1'
          }
        })
        console.log(this.fileList)
      },
      handleRemove(file) {
        console.log(file)
        console.log(this.fileList)
        this.fileList.forEach((item, index) => {
          if (item.uid == file.uid) {
            this.fileList.splice(index, 1)
          }
        })
      },
      handlePictureCardPreview(file) {
        console.log('放大')
        console.log(file)
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      // editor
      getEditorData() {
        // 通过代码获取编辑器内容
        let data = this.editor.txt.html()
        this.mineData.info.content = data
      },
      beforeDestroy() {
        // 调用销毁 API 对当前编辑器实例进行销毁
        this.editor.destroy()
        this.editor = null
      },
      handlePreview(file) {
        console.log(file)
      },
      // 上传状态改变
      onChange(file, fileList) {
        // console.log('上传状态改变 的钩子')
        // console.log(file)
        // console.log(fileList)
      },
      // 上传前
      loadBefore(file) {
        console.log('上传前 的钩子')
        console.log(file)
        if (file.size > 5242880) {
          this.$message.error('本地上传图片大小不能超过5MB')
          return false
        }
        if (
          file.type == 'image/png' ||
          file.type == 'image/jpeg' ||
          file.type == 'image/gif' ||
          file.type == 'image/bmp'
        ) {
        } else {
          this.$message.error('上传图片支持gif、jpg、png、jpeg、bmp、jfif')
          return false
        }
      },
      // 上传时 的钩子
      onloadEvent(res, file, fileList) {
        // console.log('上传时 的钩子')
        // console.log(res)
        // console.log(file)
        // console.log(fileList)
      },
      // 上传成功
      onloadSucc(res, file, fileList) {
        console.log('上传成功时 的钩子')
        console.log(res)
        console.log(file)
        console.log(fileList)
        if (res.code == 200) {
          this.fileList = fileList
          this.fileList[this.fileList.length - 1].url = res.data[0].url
          this.fileList[this.fileList.length - 1].info = res.data[0].name
          this.fileList[this.fileList.length - 1].type = file.raw.type
          this.fileList[this.fileList.length - 1].is_default = '0'
          if (fileList.length == 1) {
            fileList[0].is_default = '1'
          }
          console.log(this.fileList)
        }
      },
      // 上传失败
      onloadError(err, file, fileList) {
        console.log('上传失败 的钩子')
        console.log(err)
        console.log(file)
        console.log(fileList)
        this.$message.error('上传图片失败，请重试')
        return false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    min-height: 510px;
  }
  .upPic {
    margin: 15px 0;
  }
  .el-upload-list__item {
    margin: 0 20px 8px 0;
  }
  .upPic {
    margin: 0;
  }
  .el-alert {
    margin: 0;
  }
  .el-dialog__wrapper {
    z-index: 9999999999 !important;
  }
  .picD {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    float: right;
    width: 35px;
    color: #fff;
    text-align: center;
    content: '';
    background: rgb(84, 85, 84, 0.5);
    border-radius: 20px;
  }
  .df {
    display: flex;
    justify-content: flex-start;
  }
</style>
